<template>
  <div :class="{'user-rank': isUser}" class="ranking-entry-box">
    <div class="rank-sub">
      <p class="rank-number">{{ rankNumber }}</p>
      <p class="name">{{ name }}</p>
    </div>

    <div class="rank-sub score-wrapper">
      <img src="../../../assets/icons/icn_bits.svg" class="rank-icon" alt=""/>
      <p class="score">{{ score }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RankingEntry',
  props: {
    rankNumber: {
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    score: {
      type: Number,
      required: true
    },
    isUser: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.ranking-entry-box {
  background-color: white;
  border-radius: rem(20);
  display: flex;
  justify-content: space-between;
  padding: rem(24) rem(33) rem(24) rem(24);

  .rank-sub {
    align-items: center;
    display: flex;
  }

  .rank-number {
    font-size: rem(20);
    font-weight: 600;
    letter-spacing: 0;
    line-height: rem(24);
    margin-right: rem(59);
    width: rem(23);
  }

  .name {
    font-size: rem(20);
    font-weight: 600;
    letter-spacing: 0;
    line-height: rem(24);
  }

  .rank-icon {
    height: rem(28);
    width: rem(28);
    margin-right: rem(12);
  }

  .score-wrapper {
    width: rem(100);
  }

  .score {
    font-size: rem(16);
    letter-spacing: 0;
    line-height: rem(19);
  }
}

/** Active - User Rank **/
.user-rank {
  background: linear-gradient(147.32deg, #266DB1 0%, #B9266C 100%);
  color: white;
}
</style>
